import Router from 'vue-router'

export default new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      component: () => import("../views/SystemHome.vue"),
      redirect: "/main-page",
      children: [
        {
          path: "main-page",
          name: "main-page",
          component: () => import("../views/MainPage.vue"),
        },

        {
          path: "history-page",
          name: "history-page",
          component: () => import("../views/HistoryPage.vue"),
        },

        {
          path: "photography-page",
          name: "photography-page",
          component: () => import("../views/PhotographyPage.vue"),
        },
      ]
    },
  ]
})
